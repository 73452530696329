import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const sellerApi = createApi({
  reducerPath: "sellerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/v1/crm",
    prepareHeaders: (headers, { getState }) => {
      const { adminLogin } = getState();
      const token = adminLogin?.admin?.token;

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["Sellers"],
  endpoints: (builder) => ({
    getSellers: builder.query({
      query: ({ pageNumber = 1 }) => `/customer_crm_list?page=${pageNumber}`,
      providesTags: ["Sellers"],
    }),
    getSellerById: builder.query({
      query: (id) => `/${id}/`, // Fetch single seller by ID
    }),
    createSeller: builder.mutation({
      query: (postData) => {
        return {
          url: `/create_seller/customer_crm_info/`,
          method: "POST",
          body: postData,
        };
      },
      providesTags: ["Sellers"],
    }),
    updateSeller: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/${id}/`,
        method: "PUT",
        body: data,
      }),
    }),
    // deleteSeller: builder.mutation({
    //   query: (id) => ({
    //     url: `/${id}/`,
    //     method: "DELETE",
    //   }),
    // }),
  }),
});

export const {
  useGetSellersQuery,
  useGetSellerByIdQuery,
  useCreateSellerMutation,
  useUpdateSellerMutation,
  useDeleteSellerMutation,
} = sellerApi;
