import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { MDBBtn } from "mdb-react-ui-kit";
import { useReactToPrint } from "react-to-print";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { crmInfoDetailAction } from "../../../actions/sellerInfoAction";
import TableContainer from "@mui/material/TableContainer";
import "./flags.scss";

export const OrderTableView = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const crmDetails = useSelector((state) => state.crmInfoDetail);
  const { crm_info_details, success, loading } = crmDetails;
  const pdfRef = useRef();
  const printRef = useRef();

  useEffect(() => {
    dispatch(crmInfoDetailAction(id));
  }, [id, dispatch]);

  const downloadPDF = () => {
    const input = pdfRef.current;

    html2canvas(input, { useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 10;

      pdf.addImage(
        imgData,
        "JPEG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save("invoice.pdf");
    });
  };
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <>
      {success && (
        <>
          <div className="order-view-table" ref={printRef}>
            <div className="brand-container">
              <div>
                <img
                  src="https://buybackart-s3-media-storage.s3.ap-south-1.amazonaws.com/static/extras/logobuy-removebg-preview.webp"
                  alt=""
                />
              </div>
              <div className="order-date">
                Date:{" "}
                <span>
                  {crm_info_details &&
                    new Intl.DateTimeFormat("en-GB", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                      timeZone: "Asia/Kolkata",
                    }).format(new Date(crm_info_details?.timestamp))}
                </span>
              </div>
            </div>
            <div className="crm-info">
              <div className="crm-header">
                <h2>Sale Agreement</h2>
              </div>
              <div className="customer-info">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <span style={{ fontWeight: "800" }}>
                            GSTIN: 07DMUPA7748L1Z9
                          </span>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell align="left">
                          <span style={{ fontWeight: "800" }}>
                            Reciept No: {crm_info_details?.id}
                          </span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ color: "white" }}>
                      <TableRow>
                        <TableCell align="left">
                          Name: {crm_info_details?.name_on_document}
                        </TableCell>
                        <TableCell align="left">
                          Mobile: +91 {crm_info_details?.customer_mobile}
                        </TableCell>
                        <TableCell align="left">
                          DOB: {crm_info_details?.dob}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">
                          Email: {crm_info_details?.email}
                        </TableCell>
                        <TableCell align="left">
                          Payment Mode: {crm_info_details?.payment_model}
                        </TableCell>
                        <TableCell align="left">
                          UPI ID {crm_info_details?.upi_id}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">
                          Adreess: {crm_info_details?.customer_address}
                        </TableCell>
                        <TableCell align="left">
                          Brand: {crm_info_details?.product_brand}
                        </TableCell>
                        <TableCell align="left">
                          Model: {crm_info_details?.product_model}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">
                          RAM: {crm_info_details?.ram}
                        </TableCell>
                        <TableCell align="left">
                          Storage: {crm_info_details?.storage}
                        </TableCell>
                        <TableCell align="left">
                          IMEI No: {crm_info_details?.imei_num}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">
                          <span>Device Color:</span>{" "}
                          {crm_info_details?.device_color}
                        </TableCell>
                        <TableCell align="left" colSpan={2}>
                          <span>Product Warranty:</span>{" "}
                          {crm_info_details?.warranty}
                        </TableCell>
                      </TableRow>
                      {crm_info_details.reason_of_return && (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <span style={{ fontWeight: "800" }}>
                              Reason of Return Device:
                            </span>{" "}
                            {crm_info_details?.reason_of_return}
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell colSpan={3} align="left">
                          <span style={{ fontWeight: "800" }}>
                            Device Cost:
                          </span>{" "}
                          {crm_info_details?.final_price}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        {crm_info_details.customersellerinfo.map((item) => (
                          <TableCell align="center" key={item.id}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <div>{item.document_type}</div>
                              <img
                                src={item.document_image}
                                alt="Customer"
                                className="photo"
                                style={{
                                  maxWidth: "160px",
                                  maxHeight: "150px",
                                }}
                              />
                            </div>
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        {crm_info_details.aadhaar_documents?.map((item) => (
                          <TableCell
                            align="center"
                            key={item.id}
                            colSpan={crm_info_details.customersellerinfo.length}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <div>Aadhaar Back Front Photo</div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  gap: "10px",
                                  width: "100%",
                                }}
                              >
                                <img
                                  src={item.back_image}
                                  alt="Customer Aadhaar Back"
                                  className="photo"
                                  style={{
                                    maxWidth: "160px",
                                    maxHeight: "150px",
                                  }}
                                />
                                <img
                                  src={item.front_image}
                                  alt="Customer Aadhaar Front"
                                  className="photo"
                                  style={{
                                    maxWidth: "160px",
                                    maxHeight: "150px",
                                  }}
                                />
                              </div>
                            </div>
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={3}>
                          <ul>
                            <li>
                              I agree to <strong>Terms & Conditions</strong>{" "}
                              imposed by BuyBacKart
                            </li>
                            <li>
                              I abide by all <strong>Terms & Conditions</strong>{" "}
                              of BuyBacKart
                            </li>
                          </ul>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div className="terms-conditions">
                <div className="sign">
                  <h6 style={{ fontWeight: "800" }}>Signature</h6>
                  <img
                    src={crm_info_details.signature}
                    alt="Customer Aadhaar Back"
                    className="photo"
                    style={{
                      maxWidth: "160px",
                      maxHeight: "150px",
                    }}
                  />
                </div>
              </div>
              <div className="address">
                <p>
                  Address : DLF Prime Tower 305, 3rd Floor Okhla Phase 1 New
                  Delhi - 110020 <br />
                  <strong>GSTIN: 07DMUPA7748L1Z9</strong>
                </p>
              </div>
            </div>
          </div>

          <div className="crm-button">
            <MDBBtn
              onClick={handlePrint}
              type="button"
              style={{ height: "3.5rem", width: "10rem" }}
              block
            >
              Download Sales Invoice
            </MDBBtn>
          </div>
        </>
      )}
    </>
  );
};
