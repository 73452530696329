import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { mainOrderListAction } from "../../../actions/pickupOrderAction";
import { CRM_INFO_UPDATE_RESET } from "../../../constants/sellerInfoConstant";
import { SELLER_INFO_UPDATE_RESET } from "../../../constants/sellerInfoConstant";
import { PropagateLoader, FadeLoader } from "react-spinners";
import { useGetOrdersQuery } from "../../../api/orderListApi";
import { useNavigate, useLocation } from "react-router-dom";
import { Typography } from "antd";
import { Alert } from "react-bootstrap";
import { SearchBar } from "../SearchBar/SearchBar";
import {
  getOrderParams,
  setOrderParams,
  resetOrderParams,
} from "../../../urlParams/orderParams";
import {
  Pagination,
  Stack,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TableRow,
  Paper,
  Checkbox,
} from "@mui/material";
import "./Table.scss";

export default function BasicTable({ handleTotalOrder }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [search_params, setSearchParams] = useState("");
  const order_list = useSelector((state) => state.mainOrderList);
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  // const {
  //   main_orders,
  //   loading: isLoading,
  //   error: oError,
  //   success,
  // } = order_list;
  const adminLogin = useSelector((state) => state.adminLogin);
  const { admin } = adminLogin;
  const location = useLocation();
  const { pathname } = location;
  const getOrderList = useGetOrdersQuery({ pageNumber: page });
  const {
    isError: oError,
    isFetching: fetching,
    isLoading,
    isSuccess: success,
    data: main_orders,
    refetch,
  } = getOrderList;

  useEffect(() => {
    if (admin) {
      const updatedParams = { pageNumber: page };
      setOrderParams(updatedParams);
      // dispatch(mainOrderListAction(getOrderParams()));
    }
  }, [admin]);

  useEffect(() => {
    if (main_orders && main_orders.count) {
      const { count } = main_orders;
      handleTotalOrder(count);
    }
  }, [main_orders, handleTotalOrder]);

  useEffect(() => {
    if (success) {
      setSearchParams("");
    }
  }, [success]);

  // useEffect(() => {
  //   refetch();
  // }, [page, refetch]);

  useEffect(() => {
    return () => {
      resetOrderParams();
    };
  }, [pathname]);

  const handlePageChange = async (event, value) => {
    setPage(value);
    if (admin) {
      const updatedParams = { pageNumber: value };
      setOrderParams(updatedParams);
      // dispatch(mainOrderListAction(getOrderParams()));
    }
  };

  useEffect(() => {
    if (fetching === false) {
      setLoadingNextPage(false);
    }
    if (!isLoading && fetching) {
      setLoadingNextPage(true);
    }
  }, [fetching, isLoading]);

  const totalPageCount = useMemo(() => {
    const limit = 50;
    if (main_orders && main_orders.count) {
      const { count } = main_orders;

      return Math.ceil(count / limit);
    }
  }, [main_orders]);

  const total_page = parseInt(totalPageCount, 10);

  // const handlePageChange = (event, value) => {
  //   setPage(value);
  // };

  const search = useMemo(() => {
    if (main_orders && main_orders.results) {
      return main_orders.results.filter(
        (order) =>
          order.phone_number.includes(search_params) ||
          order.user?.toLowerCase().includes(search_params) ||
          order.product.toLowerCase().includes(search_params)
      );
    }
  }, [main_orders, search_params]);

  const onClickBtn = () => {
    if (search_params) {
      const updatedParams = { search: search_params };
      setOrderParams(updatedParams);
      dispatch(mainOrderListAction(getOrderParams()));
    }
  };
  useEffect(() => {
    dispatch({ type: SELLER_INFO_UPDATE_RESET });
    dispatch({ type: CRM_INFO_UPDATE_RESET });
  }, [dispatch]);

  // useEffect(() => {
  //   let timeoutId;
  //   if (search_params !== "") {
  //     if (timeoutId) {
  //       clearTimeout(timeoutId);
  //     }
  //     timeoutId = setTimeout(() => {
  //       const updatedParams = { search: search_params };
  //       setOrderParams(updatedParams);
  //       dispatch(mainOrderListAction(getOrderParams()));
  //     }, 1000);
  //   }

  //   return () => {
  //     if (timeoutId) {
  //       clearTimeout(timeoutId);
  //     }
  //   };
  // }, [search_params]);

  const handleOrderDetail = (id) => {
    navigate(`/crm/update/${id}`);
  };

  return (
    <>
      <div className="mainTable">
        <div className="search">
          {success && (
            <>
              <Typography.Title level={4}>Recent Orders</Typography.Title>
              <SearchBar
                search_params={search_params}
                setSearchParams={setSearchParams}
                onClickBtn={onClickBtn}
              />
            </>
          )}
        </div>
        {isLoading && (
          <div className="spinner">
            <FadeLoader color="#36d7b7" />
          </div>
        )}
        {oError && (
          <div className="error-container">
            <Alert variant="danger" style={{ backgroundColor: "#ffffff" }}>
              <Alert.Heading>Error</Alert.Heading>
              <p>{oError}</p>
            </Alert>
          </div>
        )}
        <>
          {!oError && (
            <TableContainer
              // className="scrollable-content"
              className={`scrollable-content ${
                !isLoading && loadingNextPage ? "dimmed" : ""
              }`}
              component={Paper}
            >
              <div
                className={
                  !isLoading && loadingNextPage ? "loading-overlay" : ""
                }
              >
                {!isLoading && loadingNextPage && (
                  <FadeLoader color="#36d7b7" />
                )}
              </div>
              {success && (
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>User</TableCell>
                      <TableCell align="left">Phone Number</TableCell>
                      <TableCell align="left">Product</TableCell>
                      <TableCell align="left">Status</TableCell>
                      <TableCell align="left">Variant</TableCell>
                      <TableCell align="left">Scheduled</TableCell>
                      <TableCell align="left">Assigned to</TableCell>
                      <TableCell align="left">City</TableCell>

                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ color: "white" }}>
                    {main_orders?.results.map((order) => (
                      <TableRow
                        key={order.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox color="primary" />
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="order"
                          className="details"
                          onClick={() => handleOrderDetail(order.id)}
                        >
                          <span className="detailText">{order?.user}</span>
                        </TableCell>
                        <TableCell align="left">
                          {order?.phone_number}
                        </TableCell>
                        <TableCell align="left">{order?.product}</TableCell>
                        <TableCell>{order?.status}</TableCell>
                        <TableCell align="left">{order?.variant}</TableCell>
                        {order?.schedualed_date ? (
                          <TableCell align="left">
                            {new Date(order?.schedualed_date).toLocaleString(
                              undefined,
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                                hour: "numeric",

                                hour12: true,
                              }
                            )}
                          </TableCell>
                        ) : (
                          <TableCell align="left">None</TableCell>
                        )}
                        {order.assigned_to ? (
                          <TableCell align="left">
                            {order.assigned_to.split("_")[0]}
                          </TableCell>
                        ) : (
                          <TableCell align="left">None</TableCell>
                        )}
                        <TableCell align="left">{order?.city}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          )}

          {success && (
            <div className="pagination-container">
              <Stack spacing={0}>
                <Pagination
                  variant="outlined"
                  shape="rounded"
                  count={total_page}
                  page={page}
                  defaultPage={4}
                  onChange={handlePageChange}
                  size="small"
                />
              </Stack>
            </div>
          )}
        </>
      </div>
    </>
  );
}
