import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Card, Alert } from "react-bootstrap";
import { ORDER_UPDATE_RESET } from "../../constants/pickupOrderConstant";
import "./../Form.scss";
import imageCompression from "browser-image-compression";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useCreateSellerMutation } from "../../api/deviceSellerApi";
import { ClipLoader } from "react-spinners";
import { useGetOrdersByIdQuery } from "../../api/orderListApi";

import {
  createCustomerCRMAction,
  createCustomerCRMAction1,
} from "../../actions/sellerInfoAction";
import { PropagateLoader } from "react-spinners";
import CustomerInfoInput from "./CustomerInfoInput";
import { MDBBtn } from "mdb-react-ui-kit";
import { pickupOrederDetail } from "../../actions/pickupOrderAction";
import CustomerDocumentInput from "./CustomerDocumentInput";

// Define your validation schema
const schema = yup.object().shape({
  user: yup.string().required("User is required"),
  phone_number: yup.string().required("Phone Number is required"),
  email: yup.string().required("Email is required"),
  dob: yup.string().required("Date of Birth is required"),
  product: yup.string().required("Product is required"),
  brand: yup.string().required("Brand is required"),
  final_price: yup
    .number()
    .typeError("Device Cost must be a number")
    .required("Device Cost is required"),
  ram: yup.string().required("ram is required"),
  storage: yup.string().required("storage is required"),
  warrenty: yup
    .object()
    .shape({
      value: yup.string().required("Warranty is required"),
    })
    .nullable()
    .required("Warranty is required"),

  payment: yup
    .object()
    .shape({
      value: yup.string().required("Payment Model is required"),
    })
    .nullable()
    .required("Payment Mode is required"),
  upi: yup.string().required("UPI ID is required"),
  imei: yup.string().required("imei is required"),
  device_color: yup.string().required("device color is required"),
  pick_up_location: yup.string().required("Address is required"),
  aadhaar_front_image: yup.mixed().required("Front image is required"),
  aadhaar_back_image: yup.mixed().required("Back image is required"),
  digital_signature: yup.string().required("Signature is required"),
  // document_0: yup
  //   .object()
  //   .shape({
  //     label: yup.string().required("Document type is required"),
  //   })
  //   .nullable()
  //   .required("Document type is required"),
  // document_image0: yup.mixed().required("Document image is required"),
  // document_1: yup
  //   .object()
  //   .shape({
  //     label: yup.string().required("Document type is required"),
  //   })
  //   .nullable()
  //   .required("Document type is required"),
  // document_image1: yup.mixed().required("Document image is required"),
});

export default function CreateCustomerCRM() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const createSellerInfo = useSelector((state) => state.updateSellerInfo);
  // const { loading: sellerLoading, success: createSellerSuccess } =
  //   createSellerInfo;
  const [
    createSeller,
    {
      isLoading: sellerLoading,
      isSuccess: createSellerSuccess,
      isError: createSellerError,
    },
  ] = useCreateSellerMutation();
  
  const orderDetail = useSelector((state) => state.orderDetail);
  // const { order_detail, loading: isLoading, error: oError } = orderDetail;
  const orderUpdate = useSelector((state) => state.orderUpdate);
  const { order_update } = orderUpdate;
  const { id } = useParams();
  const getOrderDetails = useGetOrdersByIdQuery(id);
  const { isError: oError, isLoading, isSuccess, data: order_detail } = getOrderDetails;
  
  const [filesState, setFilesState] = useState({
    aadhaar_front_image: null,
    aadhaar_back_image: null,
    documents: [],
  });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      user: order_detail?.user,
      final_price: order_detail?.final_price,
      pick_up_location: order_detail?.pick_up_location,
      phone_number: order_detail?.phone_number,
      product: order_detail?.product,
      brand: order_detail?.brand_name,
      email: order_detail?.email,
    },
  });

  useEffect(() => {
    if (createSellerSuccess) {
      navigate("/crm");
    }
  }, [createSellerSuccess, navigate]);

  useEffect(() => {
    if (order_detail) {
      setValue("user", order_detail?.user);
      setValue("final_price", order_detail.final_price);
      setValue("pick_up_location", order_detail.pick_up_location);
      setValue("phone_number", order_detail?.phone_number);
      setValue("brand", order_detail?.brand_name);
      setValue("product", order_detail?.product);
      setValue("email", order_detail?.email);
    }
  }, [order_detail, setValue]);

  useEffect(() => {
    if (order_update) {
      setTimeout(() => {
        navigate("/orders", { replace: true });
      }, 1000);
    }
    dispatch({ type: ORDER_UPDATE_RESET });
  }, [order_update, navigate, dispatch]);

  useEffect(() => {
    dispatch(pickupOrederDetail(id));
  }, [id, dispatch]);

  const orderSubmitForm = async (data) => {
    const postData = {
      order_id: Number(id),
      final_price: data["final_price"],
      location: data["pick_up_location"],
      imei: data["imei"],
      user: data["user"],
      email: data["email"],
      phone_number: data["phone_number"],
      ram: data["ram"],
      storage: data["storage"],
      brand: data["brand"],
      product: data["product"],
      dob: data["dob"],
      warrenty: data["warrenty"],
      upi: data["upi"],
      payment: data["payment"],
      device_color: data["device_color"],
      documents: [],
      aadhaar: {},
      signature: data.digital_signature,
    };

    // const fileToBase64 = (file) => {
    //   try {
    //     return new Promise((resolve, reject) => {
    //       const reader = new FileReader();
    //       reader.onloadend = () => resolve(reader.result);
    //       reader.onerror = reject;
    //       reader.readAsDataURL(file);
    //     });
    //   } catch (error) {
    //     console.error("Error compressing the image:", error);
    //   }
    // };

    const validateImage = (file) => {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      const maxSizeInBytes = 10 * 1024 * 1024; // 10MB

      if (!allowedTypes.includes(file.type)) {
        alert("Invalid file type. Please upload a JPEG or PNG image.");
        return false;
      }

      if (file.size > maxSizeInBytes) {
        alert("File size exceeds 10MB. Please upload a smaller image.");
        return false;
      }

      return true;
    };

    const fileToBase64 = async (file) => {
      try {
        // Check if the file size exceeds 500 KB and compress if necessary
        if (file.size > 1000 * 1024) {
          const options = {
            maxSizeMB: 0.3, // Set the maximum size to around 400 KB
            maxWidthOrHeight: file.width, // Maintain original dimensions
            useWebWorker: true,
          };

          try {
            file = await imageCompression(file, options);
          } catch (error) {
            console.error("Error compressing image:", error);
            // Proceed with the original file if compression fails
          }
        }

        // Convert the file (compressed or original) to base64
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      } catch (error) {
        console.error("Error converting the image to base64:", error);
        throw error;
      }
    };

    const documentPromises = [];

    for (const key in data) {
      if (key.startsWith("document_")) {
        const documentIndex = parseInt(key.split("_")[1]);
        const documentValue = data[key];
        const documentImageKey = `document_image${documentIndex}`;

        const documentImageValue = data[documentImageKey];

        if (documentValue && documentImageValue) {
          if (documentImageValue[0] instanceof File) {
            const file = documentImageValue[0];

            if (validateImage(file)) {
              const promise = fileToBase64(file).then((base64Image) => {
                return {
                  document_type: documentValue
                    ? documentValue.label
                    : undefined,
                  document_image: base64Image || "",
                };
              });
              documentPromises.push(promise);
            } else {
              return;
            }
          } else {
            console.error(
              `document_image${documentIndex} is not a File instance:`,
              documentImageValue
            );
          }
        }
      } else {
        if (data[key]) {
          postData[key] = data[key];
        }
      }
    }

    postData.documents = await Promise.all(documentPromises);

    // Process Aadhaar document
    if (data.aadhaar_front_image[0] && data.aadhaar_back_image[0]) {
      const aadhaarFrontImage = data.aadhaar_front_image[0];
      const aadhaarBackImage = data.aadhaar_back_image[0];

      if (validateImage(aadhaarFrontImage) && validateImage(aadhaarBackImage)) {
        const aadhaarFrontImageBase64 = await fileToBase64(
          data.aadhaar_front_image[0]
        );
        const aadhaarBackImageBase64 = await fileToBase64(
          data.aadhaar_back_image[0]
        );

        postData.aadhaar = {
          front_image: aadhaarFrontImageBase64,
          back_image: aadhaarBackImageBase64,
        };
      } else {
        return;
      }
    }

    // dispatch(createCustomerCRMAction(postData));
  };

  const compressImage = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (event) => {
        img.src = event.target.result;
      };

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const width = 1200; // Desired width
        canvas.width = width;
        canvas.height = (img.height * width) / img.width;

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(
          (blob) => {
            blob ? resolve(blob) : reject(new Error("Compression failed"));
          },
          "image/jpeg",
          0.75
        ); // Adjust quality as needed
      };

      reader.readAsDataURL(file);
    });
  };

  const orderSubmitForm2 = async (data) => {
    // Create FormData instance
    const formData = new FormData();
    // Add non-file fields (all other form fields) to FormData
    formData.append("order_id", Number(id));
    formData.append("final_price", data["final_price"]);
    formData.append("customer_address", data["pick_up_location"]);
    formData.append("imei_num", data["imei"]);
    formData.append("name_on_document", data["user"]);
    formData.append("email", data["email"]);
    formData.append("customer_mobile", data["phone_number"]);
    formData.append("ram", data["ram"]);
    formData.append("storage", data["storage"]);
    formData.append("product_brand", data["brand"]);
    formData.append("product_model", data["product"]);
    formData.append("dob", data["dob"]);
    formData.append("warranty", data["warrenty"]["label"]);
    formData.append("upi_id", data["upi"]);
    formData.append("payment_model", data["payment"]["value"]);
    formData.append("device_color", data["device_color"]);
    formData.append("signature", data["digital_signature"]);
    // Validate and append documents
    const validateImage = (file) => {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!allowedTypes.includes(file.type) || file.size > 10 * 1024 * 1024) {
        alert("Invalid file. Please upload a JPEG/PNG image under 10MB.");
        return false;
      }
      return true;
    };

    // Process documents
    for (const key in data) {
      if (key.startsWith("document_")) {
        const documentIndex = parseInt(key.split("_")[1]);
        const documentValue = data[key];
        const documentImageKey = `document_image${documentIndex}`;
        const documentImageValue = data[documentImageKey];

        if (documentValue && documentImageValue) {
          if (documentImageValue[0] instanceof File) {
            const file = documentImageValue[0];
            if (validateImage(file)) {
              let imageToUpload = file;

              // Compress the image only if it exceeds 1MB (1MB = 1024 * 1024 bytes)
              if (file.size > 1 * 1024 * 1024) {
                imageToUpload = await compressImage(file);
              }

              // const compressedImage = await compressImage(file);
              const fileName = `document_${file}.jpg`;
              // Add document type and image file directly to FormData
              formData.append(
                `documents[${documentIndex}][document_type]`,
                documentValue.label
              );
              formData.append(
                `documents[${documentIndex}][document_image]`,
                imageToUpload,
                `${fileName}`
              );
            } else {
              return; // Invalid image, stop processing
            }
          }
        }
      }
    }

    const compressAndAppendImage = async (image, formData, fieldName) => {
      // const compressedImage = await compressImage(image);
      let imageToUpload = image;

      // Compress the image only if it exceeds 1MB (1MB = 1024 * 1024 bytes)
      if (image.size > 1 * 1024 * 1024) {
        imageToUpload = await compressImage(image);
      }

      formData.append(fieldName, imageToUpload, `${fieldName}.jpg`);
    };
    // Process Aadhaar images
    if (data.aadhaar_front_image[0] && data.aadhaar_back_image[0]) {
      const aadhaarFrontImage = data.aadhaar_front_image[0];
      const aadhaarBackImage = data.aadhaar_back_image[0];

      // Validate images together before processing
      if (validateImage(aadhaarFrontImage) && validateImage(aadhaarBackImage)) {
        await Promise.all([
          compressAndAppendImage(
            aadhaarFrontImage,
            formData,
            "aadhaar[front_image]"
          ),
          compressAndAppendImage(
            aadhaarBackImage,
            formData,
            "aadhaar[back_image]"
          ),
        ]);
      } else {
        return;
      }
    }
    await createSeller(formData).unwrap();
    // dispatch(createCustomerCRMAction1(formData));
  };

  return (
    <>
      <div className="order-form">
        {isLoading && (
          <div className="loading-container">
            <PropagateLoader color="#36d7b7" />
          </div>
        )}
        {oError && (
          <div className="error-container">
            <Alert variant="danger" style={{ backgroundColor: "#ffffff" }}>
              <Alert.Heading>Error</Alert.Heading>
              <p>{oError}</p>
            </Alert>
          </div>
        )}
        {order_detail && (
          <>
            <Card>
              <form onSubmit={handleSubmit(orderSubmitForm2)}>
                <div className="custom-border">
                  <CustomerInfoInput
                    order_detail={order_detail}
                    control={control}
                    errors={errors}
                  />
                  <CustomerDocumentInput
                    control={control}
                    seller_info_list={order_detail}
                    errors={errors}
                  />
                </div>
                {
                  createSellerError && (
                    <p style={{color: "red"}}>{createSellerError}</p>
                  )
                }
               
                <div className="update-btton-container">
                  <MDBBtn
                    // className="mt-4"
                    className={`${sellerLoading ? "dimmed-button" : ""}`}
                    type="submit"
                    style={{ height: "2.5rem", width: "6rem" }}
                    block
                  >
                    {sellerLoading && <ClipLoader color="#36d7b7" size={20} />}
                    Update
                  </MDBBtn>
                </div>
              </form>
            </Card>
          </>
        )}
      </div>
    </>
  );
}
