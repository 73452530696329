import { useEffect, useState, useMemo } from "react";
import "./OrderTable.scss";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { Checkbox } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { CRM_INFO_UPDATE_RESET } from "../../../constants/sellerInfoConstant";
import TableContainer from "@mui/material/TableContainer";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { downloadPdfAction } from "../../../actions/sellerInfoAction";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Alert } from "react-bootstrap";
import { useGetSellersQuery } from "../../../api/deviceSellerApi";
import { crmInfoListAction } from "../../../actions/sellerInfoAction";
import {
  getOrderParams,
  setOrderParams,
  resetOrderParams,
} from "../../../urlParams/orderParams";
import { FadeLoader } from "react-spinners";
import { SELLER_INFO_UPDATE_RESET } from "../../../constants/sellerInfoConstant";

export default function OrderTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedPdfItemId, setSelectedPdfItemId] = useState();
  const adminLogin = useSelector((state) => state.adminLogin);
  const { admin } = adminLogin;
  const pdfDownlaod = useSelector((state) => state.pdfDownload);

  const { loading: isPdfLoading } = pdfDownlaod;
  // const crm = useSelector((state) => state.crmInfoList);
  // const {
  //   crm_info,
  //   success: isCrmSuccess,
  //   loading: isCrmDataLoad,
  //   error: oError,
  // } = crm;
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const [isItemSelected, setIsItemSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState("small");
  const location = useLocation();
  const { pathname } = location;
  const deviceSellerList = useGetSellersQuery({ pageNumber: page });
  const {
    data: { results: crm_info, count } = {},
    isError: oError,
    isFetching: isCrmDataFetching,
    isLoading: isCrmDataLoad,
    isSuccess: isCrmSuccess,
    refetch,
  } = deviceSellerList;

  // useEffect(() => {
  //   if (admin) {
  //     const updatedParams = { pageNumber: page };
  //     setOrderParams(updatedParams);
  //     dispatch(crmInfoListAction(getOrderParams()));
  //   }
  // }, [admin, dispatch]);

  useEffect(() => {
    return () => {
      resetOrderParams();
    };
  }, [pathname]);

  const totalPageCount = useMemo(() => {
    const limit = 50;
    if (crm_info && count) {
      return Math.ceil(count / limit);
    }
  }, [crm_info, count]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 480) {
        setSize("small");
      } else {
        setSize("large");
      }
    };

    // Initial sizing
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {

  //   refetch();
  // }, [page, refetch]);

  const total_page = parseInt(totalPageCount);

  const handlePageChange = async (event, value) => {
    setPage(value);
    if (admin) {
      const updatedParams = { pageNumber: value };
      setOrderParams(updatedParams);
      // dispatch(crmInfoListAction(getOrderParams()));
    }
  };

  useEffect(() => {
    if (isCrmDataFetching === false) {
      setLoadingNextPage(false);
    }
    if (!isCrmDataLoad && isCrmDataFetching) {
      setLoadingNextPage(true);
    }
  }, [isCrmDataFetching, isCrmDataLoad]);

  useEffect(() => {
    dispatch({ type: SELLER_INFO_UPDATE_RESET });
    dispatch({ type: CRM_INFO_UPDATE_RESET });
  }, [dispatch]);

  const handleCheckBoxChange = (event, id) => {
    if (event.target.checked) {
      setIsItemSelected([...isItemSelected, id]);
    } else {
      setIsItemSelected(isItemSelected.filter((itemid) => itemid !== id));
    }
  };
  const handleOrderDetail = (id) => {
    navigate(`/crm/order-view/${id}`);
  };

  const handleOrderView = (id) => {
    navigate(`/crm/order-view/${id}`);
  };

  const handleCRMUpdate = (id) => {
    navigate(`/crm/order-update/${id}`);
  };

  const downloadPDF = (id) => {
    setSelectedPdfItemId(id);
    dispatch(downloadPdfAction(id));
  };
  return (
    <>
      <div className="orderTable">
        {/* {isCrmDataLoad && !loadingNextPage && (
          <div className="order-table-loading-container">
            <FadeLoader color="#36d7b7" />
          </div>
        )} */}
        {isCrmDataLoad && (
          <div className="order-table-loading-container">
            <FadeLoader color="#36d7b7" />
          </div>
        )}
        {oError && (
          <div className="error-container">
            <Alert variant="danger" style={{ backgroundColor: "#ffffff" }}>
              <Alert.Heading>Error</Alert.Heading>
              <p>{oError}</p>
            </Alert>
          </div>
        )}

        <>
          {/* <Typography.Title level={4}> Orders </Typography.Title> */}
          {!oError && (
            <TableContainer
              className={`scrollable-content ${
                !isCrmDataLoad && loadingNextPage
                  ? "scrollable-content dimmed"
                  : ""
              }`}
              // className="scrollable-content"
              component={Paper}
            >
              <div
                className={
                  !isCrmDataLoad && loadingNextPage ? "loading-overlay" : ""
                }
              >
                {!isCrmDataLoad && loadingNextPage && (
                  <FadeLoader color="#36d7b7" />
                )}
              </div>
              {isCrmSuccess && crm_info.length > 0 && (
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Customer Name</TableCell>
                      <TableCell align="left">Phone Number</TableCell>
                      <TableCell align="left">DOB</TableCell>
                      <TableCell align="left">Product</TableCell>
                      <TableCell align="left">Brand</TableCell>
                      <TableCell align="left">Device Color</TableCell>
                      <TableCell align="left">Warranty</TableCell>
                      <TableCell align="left">Device Cost</TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ color: "white" }}>
                    {crm_info.map((crm_info_item) => (
                      <TableRow
                        key={crm_info_item.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            key={crm_info_item.id}
                            checked={isItemSelected.includes(crm_info_item.id)}
                            inputProps={{
                              "aria-labelledby": `checkbox-label-${crm_info_item.id}`,
                            }}
                            onChange={(event) =>
                              handleCheckBoxChange(event, crm_info_item.id)
                            }
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="order"
                          className="details"
                          onClick={() => handleOrderDetail(crm_info_item.id)}
                        >
                          <span className="detailText">
                            {crm_info_item?.name_on_document}
                          </span>
                        </TableCell>
                        <TableCell align="left">
                          {crm_info_item?.customer_mobile}
                        </TableCell>
                        <TableCell align="left">{crm_info_item?.dob}</TableCell>
                        <TableCell align="left">
                          {crm_info_item?.product_brand}
                        </TableCell>
                        <TableCell>{crm_info_item?.product_model}</TableCell>
                        <TableCell align="left">
                          {crm_info_item?.device_color}
                        </TableCell>
                        <TableCell align="left">
                          {crm_info_item?.warranty}
                        </TableCell>
                        <TableCell align="left">
                          {crm_info_item?.final_price}
                        </TableCell>
                        {/* <TableCell align="left">{order?.schedualed_date}</TableCell>  */}
                        <TableCell
                          align="left"
                          // className="details"
                          className={`details ${
                            isPdfLoading &&
                            selectedPdfItemId === crm_info_item.id
                              ? "dimmed-button"
                              : ""
                          }`}
                          onClick={() => downloadPDF(crm_info_item.id)}
                        >
                          <span className="detailText">Download PDF</span>
                          {isPdfLoading &&
                            selectedPdfItemId === crm_info_item.id && (
                              <ClipLoader color="#36d7b7" size={20} />
                            )}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="details"
                          onClick={() => handleOrderView(crm_info_item.id)}
                        >
                          <span className="detailText">View</span>
                        </TableCell>
                        <TableCell
                          align="left"
                          className="details"
                          onClick={() => handleCRMUpdate(crm_info_item.id)}
                        >
                          <span className="detailText">Edit</span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          )}

          <div style={{ margin: "5px" }}>
            <Stack spacing={0}>
              <Pagination
                variant="outlined"
                shape="rounded"
                count={total_page}
                page={page}
                onChange={handlePageChange}
                // size={size}
                size="small"
              />
            </Stack>
          </div>
        </>
      </div>
    </>
  );
}
