import React from "react";
import { Typography } from "antd";
import { Controller } from "react-hook-form";
import Select from "react-select";
import "./CustomerDocumentInput.scss";
import { DOCUMENT_TYPE } from "../../Data/Data";
import SignatureCanvas from "react-signature-canvas";

export default function CustomerDocumentInput({ control, errors }) {
  const signaturePadRef = React.useRef(null);

  const validateImage = (file) => {
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB

    if (!allowedTypes.includes(file.type)) {
      alert("Invalid file type. Please upload a JPEG or PNG image.");
      return false;
    }

    if (file.size > maxSizeInBytes) {
      alert("File size exceeds 10MB. Please upload a smaller image.");
      return false;
    }

    return true;
  };

  return (
    <>
      <div className="customer-form-info">
        <Typography.Title level={4}>Customer Document</Typography.Title>

        {[0, 1, 2].map((index) => (
          <div key={index} className="customer-details">
            <div className="document-list">
              <Controller
                control={control}
                id={`document_${index}`}
                name={`document_${index}`}
                // id={`documents[${index}].document_type`}
                // name={`documents[${index}].document_type`}
                render={({ field }) => (
                  <>
                    <Select
                      isClearable={true}
                      options={DOCUMENT_TYPE}
                      placeholder="Please Select.."
                      menuPortalTarget={document.body}
                      {...field}
                      onChange={(value) => field.onChange(value)}
                    />
                    {/* {errors[`document_${index}`] && (
                      <p className="error-message">
                        {errors[`document_${index}`]?.label?.message}
                      </p>
                    )} */}
                  </>
                )}
              />
            </div>
            <div className="document-image">
              <Controller
                control={control}
                id={`document_image${index}`}
                name={`document_image${index}`}
                // id={`documents[${index}].document_image`}
                // name={`documents[${index}].document_image`}
                // defaultValue={seller_information[index]?.document_image || ""}
                render={({ field }) => (
                  <>
                    <input
                      type="file"
                      onChange={(e) => {
                        // const file = e.target.files[index];
                        // field.onChange(e.target.files);
                        const imageFile = e.target.files;
                        if (validateImage(imageFile[0])) {
                          field.onChange(imageFile);
                        }
                      }}
                    />
                    {/* {errors[`document_image${index}`] && (
                      <p className="error-message">
                        {errors[`document_image${index}`]?.message}
                      </p>
                    )} */}
                  </>
                )}
              />
            </div>
          </div>
        ))}

        <div className="aadhaar-details mt-3">
          <Typography.Title level={4}>Aadhaar Document</Typography.Title>
          <div className="aadhaar-image">
            <label>Front Image:</label>
            <Controller
              control={control}
              name="aadhaar_front_image"
              render={({ field }) => (
                <>
                  <input
                    type="file"
                    onChange={(e) => {
                      const imageFile = e.target.files;
                      if (validateImage(imageFile[0])) {
                        field.onChange(imageFile);
                      }
                    }}
                  />
                  {errors.aadhaar_front_image && (
                    <p className="error-message">
                      {errors.aadhaar_front_image.message}
                    </p>
                  )}
                </>
              )}
            />
            <label>Back Image:</label>
            <Controller
              control={control}
              name="aadhaar_back_image"
              render={({ field }) => (
                <>
                  <input
                    type="file"
                    onChange={(e) => {
                      const imageFile = e.target.files;
                      if (validateImage(imageFile[0])) {
                        field.onChange(imageFile);
                      }
                    }}
                  />
                  {errors.aadhaar_back_image && (
                    <p className="error-message">
                      {errors.aadhaar_back_image.message}
                    </p>
                  )}
                </>
              )}
            />
          </div>
        </div>
        <div className="signature-section mt-3">
          <Typography.Title level={4}>Digital Signature</Typography.Title>
          <Controller
            control={control}
            id="digital_signature"
            name="digital_signature"
            rules={{ required: "Digital signature is required" }}
            render={({ field }) => (
              <div className="signature">
                <SignatureCanvas
                  penColor="black"
                  canvasProps={{
                    className: "sigCanvas",
                    willReadFrequently: true,
                  }}
                  ref={signaturePadRef}
                  onEnd={() => {
                    document.activeElement.blur();
                    const signatureDataUrl = signaturePadRef.current
                      .getTrimmedCanvas()
                      .toDataURL("image/png");
                    field.onChange(signatureDataUrl);
                  }}
                />
                <button
                  type="button"
                  style={{ border: "none" }}
                  onClick={() => {
                    signaturePadRef.current.clear();
                    field.onChange(null);
                  }}
                >
                  Clear Signature
                </button>
                {errors.digital_signature && (
                  <p className="error-message">
                    {errors.digital_signature.message}
                  </p>
                )}
              </div>
            )}
          />
        </div>
      </div>
    </>
  );
}
