import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const orderApi = createApi({
  reducerPath: "orderApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/v1/crm",
    prepareHeaders: (headers, { getState }) => {
      const { adminLogin } = getState();
      const token = adminLogin?.admin?.token;

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: ({ pageNumber = 1 }) => `/main_pickuporder?page=${pageNumber}`,
    }),
    getOrdersById: builder.query({
      query: (id) => `/pickuporders/${id}/`,
    }),
    createOrderCrm: builder.mutation({
      query: (newSeller) => ({
        url: `/`,
        method: "POST",
        body: newSeller,
      }),
    }),
    // updateSeller: builder.mutation({
    //   query: ({ id, ...data }) => ({
    //     url: `/${id}/`,
    //     method: "PUT",
    //     body: data,
    //   }),
    // }),
    // deleteSeller: builder.mutation({
    //   query: (id) => ({
    //     url: `/${id}/`,
    //     method: "DELETE",
    //   }),
    // }),
  }),
});


export const {
    useGetOrdersQuery,
    useGetOrdersByIdQuery,
} = orderApi